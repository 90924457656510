import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/LinkResolver';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SliceZone } from '../components/SliceZone';

const PageTemplate = ({ data }) => {
  if (!data) return null;

  const pageContent = data.prismicForumPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicForumMenuLinks || {};

  // Check if URL contains "euraceconnect/forum-registration"
  const isSpecialPage = url && url.includes('euraceconnect/forum-registration');
  const titleClassName = `page-title${isSpecialPage ? ' text-special-title' : ''}`;

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} isForum>
      <Seo title={page.page_title.text} lang={lang} />
      <Container className="single-page_wrapper">
        <h1 className={titleClassName}>{page.page_title.text}</h1>
        {isSpecialPage && (
          <div className="d-flex justify-content-center mt-n2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-bird-icon lucide-bird text-special-title "
            >
              <path d="M16 7h.01" />
              <path d="M3.4 18H12a8 8 0 0 0 8-8V7a4 4 0 0 0-7.28-2.3L2 20" />
              <path d="m20 7 2 .5-2 .5" />
              <path d="M10 18v3" />
              <path d="M14 17.75V21" />
              <path d="M7 18a6 6 0 0 0 3.84-10.61" />
            </svg>
          </div>
        )}
        <SliceZone sliceZone={page.body} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PrismicForumPage($id: String, $lang: String) {
    prismicForumPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...ForumPageDataBodyTable
          ...ForumPageDataBodyText
          ...ForumPageDataBodyQuote
          ...ForumPageDataBodyReusableTable
          ...ForumPageDataForm
          ...ForumPageDataBodyFullWidthImage
          ...ForumPageDataBodyImageGallery
          ...ForumPageDataBodyTableCards
          ...ForumPageDataBodyImageHighlight
          ...ForumPageDataBodyListWithTwoColumns
          ...ForumPageDataBodyAccordion
        }
      }
    }
    prismicForumMenuLinks(lang: { eq: $lang }) {
      ...ForumMenuLinksFragment
    }
  }
`;

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
