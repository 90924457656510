import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { CustomLink } from '../../utils/CustomLink';

export const Accordion = ({ slice }) => {
  const sectionTitle = slice.primary.an_accordion_section_title;
  const items = slice.items;
  const accordionId = `accordion-${slice.id}`;
  console.dir(slice);

  return (
    <section className="content-section">
      {sectionTitle && <h2 className="text-center mb-4">{sectionTitle}</h2>}
      <div className="accordion" id={accordionId}>
        {items.map((item, index) => {
          const itemId = `collapse-${slice.id}-${index}`;
          const headingId = `heading-${slice.id}-${index}`;

          return (
            <div key={itemId} className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fs-5 text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${itemId}`}
                  aria-expanded="false"
                  aria-controls={itemId}
                >
                  {item.item_header}
                </button>
              </h2>
              <div
                id={itemId}
                className="accordion-collapse collapse"
                aria-labelledby={headingId}
                data-bs-parent={`#${accordionId}`}
              >
                <div className="accordion-body">
                  <RichText render={item.item_description.raw} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ForumPageDataBodyAccordion on PrismicForumPageDataBodyAccordion {
    id
    primary {
      an_accordion_section_title
    }
    items {
      item_header
      item_description {
        raw
      }
    }
  }
  fragment PageDataBodyAccordion on PrismicPageDataBodyAccordion {
    id
    primary {
      an_accordion_section_title
    }
    items {
      item_header
      item_description {
        raw
      }
    }
  }
  fragment NewsDataBodyAccordion on PrismicNewsDataBodyAccordion {
    id
    primary {
      an_accordion_section_title
    }
    items {
      item_header
      item_description {
        raw
      }
    }
  }
`;
